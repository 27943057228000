import React from 'react';

const InfoSection = ({logo}) => {
  // const handleSubscribe = (event) => {
  //   event.preventDefault();
  //   // Handle subscription logic here
  //   console.log('Subscribed');
  // };

  return (
    <section className="info_section layout_padding">
      <div className="container">
        <div className="info_form">
          {/* <div className="row">
            <div className="offset-lg-3 col-lg-3">
              <h5 className="form_heading">Newsletter</h5>
            </div>
            <div className="col-md-6">
              <form onSubmit={handleSubscribe}>
                <input type="email" placeholder="Enter Your email" required />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div> */}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="info_logo">
              <div>
                <a href="/">
                  {/* <img src="images/logo.png" alt="Brighton Logo" /> */}
                  <img
                    src={logo}
                    alt="Pluto Logo"
                    style={{ width: "190px" }}
                  />
                </a>
              </div>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="info_links d-flex flex-column">
              <h5>Important Links</h5>
              <a href='https://admin.demoschoolmanagement.in/' className="pr-0 pr-md-4 pr-lg-5">
                Admin Login
              </a>
              <a  href='https://teacher.demoschoolmanagement.in/' className="pr-0 pr-md-4 pr-lg-5">
                Teacher Login
              </a>
              <a  href='https://student.demoschoolmanagement.in/' className="pr-0 pr-md-4 pr-lg-5">
                Student Login
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <div className="info_insta d-flex flex-column">
              <h5>Pages</h5>
              <a  href='/termscondition' className="pr-0 pr-md-4 pr-lg-5">
                Terms & Condition
              </a>
              <a href='privacypolicy' className="pr-0 pr-md-4 pr-lg-5">
                Privacy Policy
              </a>
              <a href='/disclaimer' className="pr-0 pr-md-4 pr-lg-5">
                Disclaimer
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <div className="pl-0 pl-lg-5 pl-md-4">
              <h5>Social Media</h5>
              <a  href='https://www.facebook.com/' className="pr-0 pr-md-4 pr-lg-5">Facebook</a>
              <a  href='https://www.linkedin.com/' className="pr-0 pr-md-4 pr-lg-5">Linkedin</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoSection;
