import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
// import principal_pic from '../../images/school_principal.jpg';
import "./PrincipalsDesk.css";

Modal.setAppElement('#root'); // Set the app element to prevent accessibility issues

const PrincipalDesk = ({ principalInformation }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [notice, setNotice] = useState([]);

  useEffect(() => {
    axios
      .get('https://api.demoschoolmanagement.in/notice')
      .then((response) => {
        // console.log(response.data);
        
        setNotice(response.data.content);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  // console.log(parentFeedback);



  useEffect(() => {
    let scrollInterval;
    if (!isHovered) {
      scrollInterval = setInterval(() => {
        const board = document.getElementById('noticeBoard');
        if (board.scrollTop < board.scrollHeight - board.clientHeight) {
          board.scrollTop += 1;
        } else {
          board.scrollTop = 0; // Restart the scroll
        }
      }, 50); // Adjust speed as needed
    }

    return () => {
      clearInterval(scrollInterval);
    };
  }, [isHovered]);

  const openModal = (selectedNotice) => {
    setSelectedNotice(selectedNotice);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  // console.log(notice);
  // console.log(principalInformation);

  return (
    <div className="container mb-5">
      <div className="heading_container">
        <h2>Principal's Desk</h2>
        <p className='pb-4'>
          {principalInformation.message}
        </p>
      </div>
      <div className="principal-desk">
        <div style={{ width: '100%', maxWidth: '400px', flex: '1 1 auto' }}>
          <img
            src={principalInformation.profile_photo} // Replace with actual image path
            alt="Principal"
          />
          <h2>{principalInformation.name}</h2>
          <p>M.Sc PhD in Mathematics</p>

        </div>

        <div
          id="noticeBoard"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div>
            {notice.map((ele, index) => (
              <p className='bg-success p-2 my-2'
                key={index}
                onClick={() => openModal(ele)}
              >
                <span><span class="badge badge-pill badge-warning mx-3">Notice {index + 1}</span></span>{ele.notice_title}
              </p>
            ))}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Notice Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        {selectedNotice && (
          <div>
            <h2>Notice Details</h2>
            <p>{selectedNotice.notice_details}</p>
            <button onClick={closeModal} style={{ marginTop: '20px' }}>Close</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PrincipalDesk;
