import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FiAlignJustify } from "react-icons/fi";
import './Header.css';

const Header = () => {
  const [logo, setLogo] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

// Fetch data when the component mounts
useEffect(() => {
  axios
    .get('https://dataserver.demoschoolmanagement.in/website/get_conf.php')
    .then((response) => {
      // console.log(response.data);
      setLogo(response.data.logo);      
    })
    .catch((err) => {
      console.log(err.message);       
    });
}, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Wrap handleClickOutside in useCallback to ensure a stable reference
  const handleClickOutside = useCallback((event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isOpen) {
      setIsOpen(false); // Close the sidebar if clicked outside
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  const handleLinkClick = () => {
    setIsOpen(false); 
  };

  return (
    <div className="headerNavbar">
      <div className="navbar-brand">
        <Link to="/">
          <img
            src={logo}
            alt="Pluto Logo"
            className="logo"
          />
        </Link>
      </div>
      <div className="hamburger" onClick={toggleSidebar}>
        <FiAlignJustify />
      </div>
      <ul className="navbar-links">
        <li><Link to="/"> 🏠 Home</Link></li>
        <li><Link to="/aboutUs"> ℹ️ About Us</Link></li>
        <li><Link to="/contact"> 📞 Contact Us</Link></li>
        <li><Link to="/programe"> 📚 Programe</Link></li>
        <li><Link to="/gallery"> 🖼️ Gallery</Link></li> 
      </ul>
      <div className={`sidebar ${isOpen ? 'open' : ''}`} ref={sidebarRef}>
        <ul className="sidebar-links">
          <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
          <li><Link to="/aboutUs" onClick={handleLinkClick}>About</Link></li>
          <li><Link to="/contact" onClick={handleLinkClick}>Contact</Link></li>
          <li><Link to="/programe" onClick={handleLinkClick}>Programe</Link></li>
          <li><Link to="/gallery" onClick={handleLinkClick}>Gallery</Link></li>
          <li>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => window.location.href = "https://admin.demoschoolmanagement.in/"}
          >
            Admin Login
          </button>
        </li>
          <li>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => window.location.href = "https://teacher.demoschoolmanagement.in/"}
          >
            Teacher Login
          </button>
        </li>
        <li>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => window.location.href = "https://student.demoschoolmanagement.in/"}
          >
            Student Login
          </button>
        </li>

        </ul>
      </div>
    </div>
  );
};

export default Header;
