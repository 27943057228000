import React from 'react';
import PrincipalsDesk from '../PrincipalsDesk/PrincipalsDesk.js';


const OfferSection = ({principalInformation,notice}) => {
  return (
    <section className="offer_section hero_next_section-margin layout_padding">
      <PrincipalsDesk principalInformation={principalInformation} />
    </section>
  );
};

export default OfferSection;
